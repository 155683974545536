<template>
  <div class="navbar-header d-xl-block d-none">
    <b-link
      class="navbar-brand"
      to="/"
    >
      <b-img
        :src="appLogoImage"
        alt="logo"
        width="120"
      />
    </b-link>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>
